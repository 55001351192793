import { Flex, Td, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import EmptyState from "../../../components/EmptyState";
import LoadingAnimation from "../../../components/LoadingAnimation";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { EmptyStateType } from "../../../types/emptyState";
import { IIndexTableItem } from "../types";
import IndexActiveIcon from "./IndexActiveIcon";
import IndexDeployingIcon from "./IndexDeployingIcon";
import IndexRemovingIcon from "./IndexRemovingIcon";

const Table = ({ items }: { items: IIndexTableItem[] }) => {
  const NA_TEXT = "N/A";
  const columnHelper = createColumnHelper<IIndexTableItem>();
  const columns = [
    columnHelper.accessor("name", {
      cell: ({ getValue, row }) => {
        const name = getValue();
        return (
          <>
            <Td rowSpan={row.original.deployments.length}>
              <Text>{name}</Text>
              <AdminTableRowId id={row.original.id} />{" "}
            </Td>
          </>
        );
      },
      header: "Name"
    }),
    columnHelper.accessor("deployments", {
      header: "Version",
      cell: ({ getValue }) => {
        return getValue().map(({ version, status }) => (
          <Flex
            className={
              status === "deploying" || status === "removing" ? "fa-fade" : ""
            }
            color={
              status === "deploying" || status === "removing" ? "gray.500" : ""
            }
          >
            {version ?? "-"}
          </Flex>
        ));
      }
    }),
    columnHelper.accessor("deployments", {
      header: "Status",
      cell: ({ getValue }) => {
        return getValue().map(({ status }) => {
          switch (status) {
            case "active":
              return <IndexActiveIcon />;
            case "deploying":
              return <IndexDeployingIcon />;
            case "notDeployed":
              return <Text>Not deployed</Text>;
            case "removing":
              return <IndexRemovingIcon />;
            default:
              return <Text>Unknown status</Text>;
          }
        });
      }
    }),
    columnHelper.accessor("deployments", {
      header: "Deployed at",
      cell: ({ getValue }) => {
        return getValue().map(({ deployedAt, status }) => (
          <Text
            className={
              status === "deploying" || status === "removing" ? "fa-fade" : ""
            }
            color={
              status === "deploying" || status === "removing" ? "gray.500" : ""
            }
          >
            {deployedAt ?? NA_TEXT}
          </Text>
        ));
      }
    })
  ];
  return <DataTable data={items} columns={columns}></DataTable>;
};

const IndexesTable = ({
  isLoading,
  items
}: {
  isLoading: boolean;
  items: IIndexTableItem[];
}) => {
  if (isLoading) {
    return <LoadingAnimation />;
  }

  if (items.length === 0) {
    return <EmptyState type={EmptyStateType.NO_INDEXES} />;
  }

  //   const mockData = [
  //     {
  //       id: "gid://test",
  //       name: "test",
  //       deployments: [
  //         { version: 1, status: "active", deployedAt: "2021-01-01 10:00:00" },
  //         { version: 2, status: "deploying", deployedAt: "2021-01-02 12:00:00" }
  //       ]
  //     }
  //   ] as IIndexTableItem[];
  return <Table items={items} />;
};

export default IndexesTable;
