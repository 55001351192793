import { inject, singleton } from "tsyringe";
import { HttpService } from "./http.service";

@singleton()
export class JavascriptSchemaTypesClient {
  constructor(
    @inject("javascriptSchemaTypesHttpService")
    private readonly http: HttpService
  ) {}

  async get(_flags?: Record<string, unknown>): Promise<string> {
    const { data } = await this.http.get<string>(
      `/@enterspeed/js-schema-types@1.7.0/dist/index.d.ts`
    );
    return data;
  }
}
