import { IFilterableFieldOption } from "../types";
export const findByValue = (
  value: string
): IFilterableFieldOption | null | undefined => {
  return fields.find((f) => f.value === value);
};
export const fields: IFilterableFieldOption[] = [
  {
    value: "TraceId",
    label: "Trace ID",
    inputType: "TEXT"
  },
  {
    value: "TrackingId",
    label: "Tracking ID",
    inputType: "TEXT"
  },
  {
    value: "TransactionId",
    label: "Transaction ID",
    inputType: "TEXT"
  },
  {
    value: "ServiceName",
    label: "Service name",
    inputType: "SERVICE_PICKER"
  },
  {
    value: "Level",
    label: "Log level",
    inputType: "LOG_LEVEL_PICKER"
  },
  {
    value: "ViewId",
    label: "View ID",
    inputType: "TEXT"
  },
  {
    value: "SourceEntityId",
    label: "Source entity ID",
    inputType: "TEXT"
  },
  {
    value: "SourceEntityOriginId",
    label: "Source entity origin ID",
    inputType: "TEXT"
  },
  {
    value: "SourceId",
    label: "Source",
    inputType: "SOURCE_PICKER"
  },
  {
    value: "MappingSchemaId",
    label: "Schema",
    inputType: "MAPPING_SCHEMA_PICKER_BY_ID"
  },
  {
    value: "EnvironmentId",
    label: "Environment",
    inputType: "ENVIRONMENT_PICKER"
  },
  {
    value: "TeaserMessageTemplate",
    label: "Teaser message template",
    inputType: "TEXT"
  },
  {
    value: "ActiveRouteId",
    label: "Active route ID",
    inputType: "TEXT"
  },
  {
    value: "RouteId",
    label: "Route ID",
    inputType: "TEXT"
  }
];
