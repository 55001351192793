import { useQuery } from "@tanstack/react-query";
import { container } from "tsyringe";
import { INDEX } from "../../../constants/queryKey";
import { parseEnvironmentId } from "../../../helpers/ids";
import { EnvironmentId } from "../../../types/ids";
import { SchemaService } from "../../schemas/schema.service";

const getIndexes = ({ environmentId }: { environmentId?: EnvironmentId }) => {
  const schemaService = container.resolve(SchemaService);
  return schemaService.getSchemas({
    type: "index",
    environmentGuid: environmentId?.environmentGuid
  });
};

const useIndexes = ({ environmentId }: { environmentId?: string }) => {
  return useQuery({
    queryKey: [INDEX, environmentId],
    queryFn: () =>
      getIndexes({
        environmentId: environmentId
          ? parseEnvironmentId(environmentId)
          : undefined
      }),
    refetchInterval: 10000,
    enabled: environmentId !== undefined
  });
};

export default useIndexes;
