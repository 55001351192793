import { startCase } from "lodash";
export const getDisplayName = (input: string): string => {
  const inputNameWithoutPropertiesSuffix = input.replace("properties.", "");
  const inputNameWithoutPropertiesSuffixLowercased =
    inputNameWithoutPropertiesSuffix.toLocaleLowerCase();
  switch (inputNameWithoutPropertiesSuffixLowercased) {
    case "timestamp":
      return "Timestamp";
    case "delivercounts":
      return "Delivery counts";
    case "teasermessagetemplate":
      return "Message template";
    case "message":
      return "Message";
    case "traceid":
      return "Trace ID";
    case "trackingid":
      return "Tracking ID";
    case "transactionid":
      return "Transaction ID";
    case "servicename":
    case "service":
      return "Service";
    case "level":
      return "Level";
    case "sourceentity":
    case "sourceentityid":
      return "Source entity";
    case "mappingschema":
    case "mappingschemaid":
      return "Schema";
    case "mappingschemaversion":
    case "mappingschemaversionid":
      return "Schema version";
    case "view":
    case "viewid":
      return "View";
    case "route":
    case "routeid":
      return "Route";
    case "activeroute":
    case "activerouteid":
      return "Active route";
    case "source":
    case "sourceid":
      return "Source";
    case "environment":
    case "environmentid":
      return "Environment";
    case "environmentclient":
    case "environmentclientid":
      return "Environment client";
    case "sourcegroup":
    case "sourcegroupid":
      return "Source group";
    case "release":
    case "releaseid":
      return "Deployment";
    case "releasetotalschemas":
      return "Deploy schemas count";
    case "actlr":
    case "actorid":
      return "Actor";
    default:
      return startCase(inputNameWithoutPropertiesSuffix);
  }
};
