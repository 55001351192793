import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid
} from "@chakra-ui/react";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { GroupBase, Select } from "chakra-react-select";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import EmptyState from "../../../components/EmptyState";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import Pagination from "../../../components/Pagination";
import ReloadButton from "../../../components/ReloadButton";
import AdminTableRowId from "../../../components/table/AdminTableRowId";
import { DataTable } from "../../../components/table/DataTable";
import { SidePanelContext } from "../../../context/SidePanelContext";
import formatDateTime from "../../../helpers/formatDateTime";
import {
  getEnvironmentOption,
  getEnvironmentOptions
} from "../../../helpers/getEnvironmentOptions";
import {
  getSchemaAliasOptions,
  getSchemaOptionByAlias
} from "../../../helpers/getSchemaAliasOptions";
import {
  getSourceOption,
  getSourceOptions
} from "../../../helpers/getSourceOptions";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { useEnvironments } from "../../environments/api/getEnvironments";
import { useSchemas } from "../../schemas/api/getSchemas";
import { useSourceGroups } from "../../source-groups/api/getSourceGroups";
import { useAllViews } from "../api/getAllViews";
import { IView } from "../types";
import ViewsPanel from "./ViewsPanel";

interface IViewsFilterOption {
  name: string;
  component: ReactElement;
}

const ViewsTable = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>([
    { id: "updatedAt", desc: true }
  ]);

  const reactSelectStyles = useReactEnterspeedIdentifiableSelectStyles();
  const { data: environments } = useEnvironments();
  const { data: dataSources } = useSourceGroups();

  const [selectedEnvironment, setSelectedEnvironment] =
    useState<IEnterspeedIdentifiableSelectOption>();

  const { data: schemas } = useSchemas(
    "normal",
    undefined,
    selectedEnvironment?.value as string
  );

  const [selectedSource, setSelectedSource] = useState<
    IEnterspeedIdentifiableSelectOption | undefined
  >();

  const [inputSourceEntityOriginId, setInputSourceEntityOriginId] =
    useState("");

  const [sourceEntityOriginId] = useDebounce(inputSourceEntityOriginId, 500);

  const [selectedSchema, setSelectedSchema] =
    useState<IEnterspeedIdentifiableSelectOption>();

  const [selectedRow, setSelectedRow] = useState<string>("");

  useEffect(() => {
    if (!schemas) {
      return;
    }

    const searchSchema = searchParams?.get("alias");
    const schemaOption = searchSchema
      ? getSchemaOptionByAlias(schemas, searchSchema)
      : undefined;
    setSelectedSchema(schemaOption);
  }, [schemas, searchParams]);

  useEffect(() => {
    if (!environments) {
      return;
    }
    const searchEnvironment = searchParams?.get("env");
    const environmentOption = searchEnvironment
      ? getEnvironmentOption(environments, searchEnvironment)
      : undefined;
    setSelectedEnvironment(environmentOption);
  }, [environments, searchParams]);

  useEffect(() => {
    if (!dataSources) {
      return;
    }

    const searchSource = searchParams?.get("source");
    const dataSourceOption = searchSource
      ? getSourceOption(dataSources, searchSource)
      : undefined;
    setSelectedSource(dataSourceOption);
  }, [dataSources, searchParams]);

  useEffect(() => {
    const searchOriginId = searchParams?.get("sourceEntityOriginId");

    if (!searchOriginId) {
      return;
    }

    setInputSourceEntityOriginId(searchOriginId);
  }, [searchParams]);

  useEffect(() => {
    const isSidePanelOpen = searchParams.get("sidePanel") === "viewsPanel";
    if (isSidePanelOpen) {
      setTitle("View details");
      setContent(<ViewsPanel />);
      setOpen(true);
    }
  }, [searchParams]);

  const {
    data,
    isLoading,
    refetch,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage
  } = useAllViews({
    environmentId: selectedEnvironment?.value as string,
    params: {
      sourceEntityOriginId,
      sourceGuid: selectedSource?.value as string,
      schemaAlias: selectedSchema?.value as string,
      sortBy: sorting.map((s) => ({
        propertyName: s.id,
        direction: s.desc ? "DESC" : "ASC"
      }))
    }
  });

  const tableData = data?.pages
    ? data.pages.flatMap((page) =>
        page.results.map((item) => ({
          id: item.id.idValue,
          viewAlias: item.id.viewHandle,
          sourceEntityId: item.sourceEntityId.sourceGuid,
          originId: item.id.originId,
          sourceName: item.sourceName,
          updatedAt: item.updatedAt
        }))
      )
    : [];

  const totalViewsCount = data?.pages[0].total || 0;

  const environmentOptions = getEnvironmentOptions(environments);
  const sourceOptions = getSourceOptions(dataSources);
  const schemaOptions = getSchemaAliasOptions(schemas, undefined, true);
  const columnHelper = createColumnHelper<IView>();

  const columns = [
    columnHelper.accessor("viewAlias", {
      cell: (props) => (
        <Box>
          <Box>{props.row.original.viewAlias}</Box>
          <AdminTableRowId id={props.row.original.id} />
        </Box>
      ),
      header: "Alias",
      enableSorting: false
    }),
    columnHelper.accessor("originId", {
      cell: (info) => info.getValue(),
      header: "Origin ID",
      enableSorting: false
    }),
    columnHelper.accessor("sourceName", {
      cell: (info) => info.getValue(),
      header: "Source Name",
      enableSorting: false
    }),
    columnHelper.accessor("updatedAt", {
      cell: (info) => formatDateTime(info.getValue()),
      header: "Updated at",
      enableSorting: true
    })
  ];

  const { setOpen, setTitle, setContent, isClosing } =
    useContext(SidePanelContext);

  const handleOpenSidePanel = (id: string) => {
    const newSearch = new URLSearchParams(searchParams);
    newSearch.set("id", id);
    newSearch.set("sidePanel", "viewsPanel");
    setSearchParams(newSearch);
    setSelectedRow(id);
  };

  const handleEnvironmentFilter = (
    value: IEnterspeedIdentifiableSelectOption
  ) => {
    const newSearch = new URLSearchParams(searchParams);
    setSelectedEnvironment(value);

    if (value?.value) {
      newSearch.set("env", value.value as string);
      setSearchParams(newSearch);
    } else {
      setSearchParams(undefined);
      setSelectedSource(undefined);
      setSelectedSchema(undefined);
    }
  };

  const handleSourceFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    const newSearch = new URLSearchParams(searchParams);
    setSelectedSource(value);

    if (value?.value) {
      newSearch.set("source", value?.value as string);
    } else {
      newSearch.delete("source");
    }

    setSearchParams(newSearch);
  };

  const handleSchemaFilter = (value: IEnterspeedIdentifiableSelectOption) => {
    const newSearch = new URLSearchParams(searchParams);
    setSelectedSchema(value);
    if (!value?.value) {
      newSearch.delete("alias");
    } else {
      newSearch.set("alias", value.value as string);
    }
    setSearchParams(newSearch);
  };

  const filterOptions: IViewsFilterOption[] = [
    {
      name: "Environment",
      component: (
        <Select<
          IEnterspeedIdentifiableSelectOption,
          true,
          GroupBase<IEnterspeedIdentifiableSelectOption>
        >
          size="sm"
          useBasicStyles
          chakraStyles={reactSelectStyles}
          colorScheme="brand"
          isClearable
          placeholder="Select environment"
          options={environmentOptions}
          components={new EnterspeedIdentifiableSelect()}
          value={selectedEnvironment}
          onChange={(value) => {
            return handleEnvironmentFilter(
              value as unknown as IEnterspeedIdentifiableSelectOption
            );
          }}
        />
      )
    },
    {
      name: "Sources",
      component: (
        <Select<
          IEnterspeedIdentifiableSelectOption,
          true,
          GroupBase<IEnterspeedIdentifiableSelectOption>
        >
          size="sm"
          useBasicStyles
          chakraStyles={reactSelectStyles}
          colorScheme="brand"
          isClearable
          placeholder="Select source"
          options={sourceOptions}
          components={new EnterspeedIdentifiableSelect()}
          value={selectedSource}
          onChange={(value) => {
            return handleSourceFilter(
              value as unknown as IEnterspeedIdentifiableSelectOption
            );
          }}
        />
      )
    },
    {
      name: "Schema",
      component: (
        <Select<
          IEnterspeedIdentifiableSelectOption,
          true,
          GroupBase<IEnterspeedIdentifiableSelectOption>
        >
          size="sm"
          useBasicStyles
          chakraStyles={reactSelectStyles}
          colorScheme="brand"
          isClearable
          placeholder="Select schema"
          options={schemaOptions}
          components={new EnterspeedIdentifiableSelect()}
          value={selectedSchema}
          onChange={(value) => {
            return handleSchemaFilter(
              value as unknown as IEnterspeedIdentifiableSelectOption
            );
          }}
        />
      )
    }
  ];

  useEffect(() => {
    if (isClosing) {
      setSelectedRow("");

      const newSearch = new URLSearchParams(searchParams);
      newSearch.delete("id");
      newSearch.delete("sidePanel");
      setSearchParams(searchParams);
    }
  }, [isClosing]);

  const handleSourceEntityIdFilterSearch = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.length <= 2) {
      setInputSourceEntityOriginId("");

      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.delete("sourceEntityOriginId");
      if (!pathname) {
        return;
      }
      navigate(`${pathname}?${newSearch.toString()}`);
    }
    if (e.target.value.length > 2) {
      setInputSourceEntityOriginId(e.target.value);
      const newSearch = new URLSearchParams(searchParams ?? undefined);
      newSearch.set("sourceEntityOriginId", e.target.value);
      if (!pathname) {
        return;
      }
      navigate(`${pathname}?${newSearch.toString()}`);
    }
  };

  return (
    <>
      <Box>
        <SimpleGrid spacing={8} columns={4} mb="8">
          {filterOptions
            .filter(
              (options) =>
                (!selectedEnvironment && options.name === "Environment") ||
                selectedEnvironment
            )
            .map((filterOption) => {
              return (
                <FormControl key={filterOption.name}>
                  <FormLabel color="gray.500" fontSize="xs">
                    {filterOption.name}
                  </FormLabel>
                  {filterOption.component}
                </FormControl>
              );
            })}
          {selectedEnvironment && (
            <Flex>
              <FormControl mr="4">
                <FormLabel color="gray.500" fontSize="xs">
                  Source Entity id
                </FormLabel>

                <Input
                  defaultValue={inputSourceEntityOriginId}
                  onChange={handleSourceEntityIdFilterSearch}
                  placeholder="Enter Source Entity id (min. 3 characters)"
                  size="sm"
                />
              </FormControl>
              <Flex alignItems="flex-end">
                <ReloadButton loading={isRefetching} onClick={refetch} />
              </Flex>
            </Flex>
          )}
        </SimpleGrid>
      </Box>
      {selectedEnvironment ? (
        <>
          <DataTable
            loading={isLoading || isRefetching}
            columns={columns}
            data={tableData}
            onRowClickCallback={(row: IView) => handleOpenSidePanel(row.id)}
            highlightedRow={selectedRow}
            sorting={sorting}
            setSorting={setSorting}
          />

          {tableData.length > 0 && (
            <Pagination
              disabled={
                tableData.length === totalViewsCount ||
                isLoading ||
                isFetchingNextPage
              }
              current={tableData.length}
              total={totalViewsCount}
              loadMore={fetchNextPage}
              loading={isFetchingNextPage}
            />
          )}
        </>
      ) : (
        <EmptyState customDescription="Please select an environment so we can find some views for you" />
      )}
    </>
  );
};

export default ViewsTable;
