import { parse } from "@babel/parser";
import {
  ArgumentPlaceholder,
  ArrayExpression,
  ArrowFunctionExpression,
  BooleanLiteral,
  ExportDefaultDeclaration,
  Expression,
  FunctionExpression,
  JSXNamespacedName,
  Node,
  NumericLiteral,
  ObjectExpression,
  ObjectProperty,
  SpreadElement,
  StringLiteral
} from "@babel/types";

import { singleton } from "tsyringe";
import { ObjectResult, Result } from "../../../../types/codeEditor";
import {
  IEvaluatedSchemaMetadata,
  SchemaFormat,
  SchemaType
} from "../../types";
import { BaseSchemaVisitor } from "./base";
import { ItemsFunctionValidator } from "./items-function.validator";
import { ObjectReturnStatementValidator } from "./object-return-statement.validator";
import { PropertiesFunctionValidator } from "./properties-function.validator";
import {
  SCHEMA_INVALID_CODE,
  SCHEMA_ITEMS_MUST_BE_A_FUNCTION,
  SCHEMA_PROPERTIES_MUST_BE_A_FUNCTION,
  SCHEMA_TRIGGERS_MISSING,
  SCHEMA_TRIGGERS_MUST_BE_A_FUNCTION,
  SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE
} from "./schema-errors";
import { SourceEntityReassignmentValidator } from "./source-entity-reassignment.validator";

type SourceEntityTypeArguments = Array<
  Expression | SpreadElement | JSXNamespacedName | ArgumentPlaceholder
>;
type BabelSyntaxError = SyntaxError & {
  loc: { column: number; line: number; index: number };
};

export type LiteralValue = StringLiteral | BooleanLiteral | NumericLiteral;
export type BaseFunctionExpression =
  | ArrowFunctionExpression
  | FunctionExpression;

abstract class BaseJavascriptFormatSchemaVisitor extends BaseSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "javascript";
  }

  visit(code: string): ObjectResult<IEvaluatedSchemaMetadata> {
    try {
      const schemaParsingResult = this.parse(code);
      if (!schemaParsingResult.valid) {
        return ObjectResult.fail<IEvaluatedSchemaMetadata>(
          schemaParsingResult.errors
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.visitModuleObject(schemaParsingResult.value!);
    } catch (e) {
      if (e instanceof SyntaxError) {
        const error = e as BabelSyntaxError;
        if (error.loc) {
          return ObjectResult.fail<IEvaluatedSchemaMetadata>([
            {
              description: error.message,
              location: { start: error.loc }
            }
          ]);
        }
      }
      return ObjectResult.fail<IEvaluatedSchemaMetadata>([
        { description: (e as Error).message }
      ]);
    }
  }

  abstract visitModuleObject(
    moduleObject: ObjectExpression
  ): ObjectResult<IEvaluatedSchemaMetadata>;

  protected parse(code: string): ObjectResult<ObjectExpression> {
    const parsed = parse(code, { sourceType: "module" });
    const expressionStatement = parsed.program.body.find(
      (f) => f.type === "ExportDefaultDeclaration"
    ) as ExportDefaultDeclaration;
    const objectExpression =
      expressionStatement.declaration as ObjectExpression;

    if (!objectExpression.properties) {
      return ObjectResult.fail<ObjectExpression>([
        { description: SCHEMA_INVALID_CODE }
      ]);
    }

    return ObjectResult.ok<ObjectExpression>(objectExpression);
  }

  private extractBody(
    input: Node | Expression | null | undefined
  ): SourceEntityTypeArguments {
    switch (input?.type) {
      case "ArrowFunctionExpression":
        return this.extractBody(input?.body);
      case "FunctionExpression":
        return this.extractBody(input.body);
      case "ExpressionStatement":
        return this.extractBody(input.expression);
      case "BlockStatement":
        return input.body?.flatMap((m) => this.extractBody(m));
      case "CallExpression":
        return input?.arguments ?? [];
      case "ArrayExpression":
        return input?.elements?.flatMap((f) => this.extractBody(f));
      case "ReturnStatement":
        return this.extractBody(input.argument);
      default:
        return [];
    }
  }

  protected validateTriggers(
    moduleObject: ObjectExpression
  ): ObjectResult<string[]> {
    const triggersProperty = moduleObject.properties
      .filter((property) => property.type === "ObjectProperty")
      .map((property) => property as ObjectProperty)
      .find(
        (property) =>
          property.key.type === "Identifier" && property.key.name === "triggers"
      );
    if (!triggersProperty) {
      return ObjectResult.fail<string[]>([
        { description: SCHEMA_TRIGGERS_MISSING }
      ]);
    }

    // triggers must be a function
    if (
      !["FunctionExpression", "ArrowFunctionExpression"].includes(
        triggersProperty.value?.type
      )
    ) {
      return ObjectResult.fail<string[]>([
        {
          description: SCHEMA_TRIGGERS_MUST_BE_A_FUNCTION,
          location: triggersProperty.loc
            ? { ...triggersProperty.loc }
            : undefined
        }
      ]);
    }

    const triggerEntityTypeNodes = this.extractBody(triggersProperty.value);
    const sourceEntityTypes = Array.from(
      new Set<string>(
        this.extractSourceEntityTypes(triggerEntityTypeNodes)
      ).values()
    );

    if (sourceEntityTypes.length < 1) {
      return ObjectResult.fail<string[]>([
        {
          description:
            SCHEMA_TRIGGERS_MUST_HAVE_A_SOURCE_GROUP_AND_AN_ENTITY_TYPE,
          location: triggersProperty.loc
            ? { ...triggersProperty.loc }
            : undefined
        }
      ]);
    }

    return ObjectResult.ok<string[]>(sourceEntityTypes);
  }

  private extractSourceEntityTypes(
    entityTypeArguments: SourceEntityTypeArguments
  ): string[] {
    return entityTypeArguments
      .filter((f) => f.type === "ArrayExpression")
      .map((m) => m as ArrayExpression)
      .flatMap((m) => m.elements.filter((f) => f?.type === "StringLiteral"))
      .map((m) => m as StringLiteral)
      .map((m) => m.value);
  }

  protected validateProperties(moduleObject: ObjectExpression): Result {
    const propertiesFunctionProperty = moduleObject.properties
      .filter((property) => property.type === "ObjectProperty")
      .map((property) => property as ObjectProperty)
      .find(
        (property) =>
          property.key.type === "Identifier" &&
          property.key.name === "properties"
      );
    if (!propertiesFunctionProperty) {
      return ObjectResult.fail<string[]>([
        { description: SCHEMA_PROPERTIES_MUST_BE_A_FUNCTION }
      ]);
    }

    const validators = [
      new PropertiesFunctionValidator(),
      new ObjectReturnStatementValidator(),
      new SourceEntityReassignmentValidator()
    ];

    const errors = validators.flatMap((v) =>
      v.validate(propertiesFunctionProperty)
    );

    if (errors.length) {
      return Result.fail(errors);
    }

    return Result.ok();
  }

  protected validateIndexMethod(moduleObject: ObjectExpression): Result {
    const propertiesFunctionProperty = moduleObject.properties
      .filter((property) => property.type === "ObjectProperty")
      .map((property) => property as ObjectProperty)
      .find(
        (property) =>
          property.key.type === "Identifier" && property.key.name === "index"
      );
    if (!propertiesFunctionProperty) {
      return ObjectResult.fail<string[]>([
        { description: SCHEMA_PROPERTIES_MUST_BE_A_FUNCTION }
      ]);
    }

    const validators = [
      new PropertiesFunctionValidator(),
      new ObjectReturnStatementValidator(),
      new SourceEntityReassignmentValidator()
    ];

    const errors = validators.flatMap((v) =>
      v.validate(propertiesFunctionProperty)
    );

    if (errors.length) {
      return Result.fail(errors);
    }

    return Result.ok();
  }

  protected validateItems(moduleObject: ObjectExpression): Result {
    const itemsFunctionProperty = moduleObject.properties
      .filter((property) => property.type === "ObjectProperty")
      .map((property) => property as ObjectProperty)
      .find(
        (property) =>
          property.key.type === "Identifier" && property.key.name === "items"
      );
    if (!itemsFunctionProperty) {
      return ObjectResult.fail<string[]>([
        { description: SCHEMA_ITEMS_MUST_BE_A_FUNCTION }
      ]);
    }

    const validators = [
      new ItemsFunctionValidator(),
      new ObjectReturnStatementValidator(),
      new SourceEntityReassignmentValidator()
    ];

    const errors = validators.flatMap((v) => v.validate(itemsFunctionProperty));

    if (errors.length) {
      return Result.fail(errors);
    }

    return Result.ok();
  }
}

@singleton()
export class JavascriptNormalSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "javascript";
  }

  get schemaTypes(): SchemaType[] {
    return ["normal"];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    const triggerSourceEntityTypesExtractionResult =
      this.validateTriggers(moduleObject);
    const propertiesValidationResult = this.validateProperties(moduleObject);

    if (
      !triggerSourceEntityTypesExtractionResult.valid ||
      !propertiesValidationResult.valid
    ) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>([
        ...(triggerSourceEntityTypesExtractionResult.errors ?? []),
        ...(propertiesValidationResult.errors ?? [])
      ]);
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: triggerSourceEntityTypesExtractionResult.value ?? []
    });
  }
}

@singleton()
export class JavascriptIndexSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "javascript";
  }

  get schemaTypes(): SchemaType[] {
    return ["index"];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  visitModuleObject(
    moduleObject: ObjectExpression
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    const triggerSourceEntityTypesExtractionResult =
      this.validateTriggers(moduleObject);
    const indexMethodResult = this.validateIndexMethod(moduleObject);

    if (
      !triggerSourceEntityTypesExtractionResult.valid ||
      !indexMethodResult.valid
    ) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>([
        ...(triggerSourceEntityTypesExtractionResult.errors ?? []),
        ...(indexMethodResult.errors ?? [])
      ]);
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: triggerSourceEntityTypesExtractionResult.value ?? []
    });
  }
}

@singleton()
export class JavascriptCollectionSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "javascript";
  }

  get schemaTypes(): SchemaType[] {
    return ["collection"];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    const triggerSourceEntityTypesExtractionResult =
      this.validateTriggers(moduleObject);
    const itemsValidationResult = this.validateItems(moduleObject);

    if (
      !triggerSourceEntityTypesExtractionResult.valid ||
      !itemsValidationResult.valid
    ) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>([
        ...(triggerSourceEntityTypesExtractionResult.errors ?? []),
        ...(itemsValidationResult.errors ?? [])
      ]);
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: triggerSourceEntityTypesExtractionResult.value ?? []
    });
  }
}

@singleton()
export class JavascriptPartialSchemaVisitor extends BaseJavascriptFormatSchemaVisitor {
  get schemaFormat(): SchemaFormat {
    return "javascript";
  }

  get schemaTypes(): SchemaType[] {
    return ["partial"];
  }

  get usingSourceGroup(): boolean {
    return true;
  }

  visitModuleObject(
    moduleObject: ObjectExpression
  ): ObjectResult<IEvaluatedSchemaMetadata> {
    const propertiesValidationResult = this.validateProperties(moduleObject);
    if (!propertiesValidationResult.valid) {
      return ObjectResult.fail<IEvaluatedSchemaMetadata>(
        propertiesValidationResult.errors
      );
    }

    return ObjectResult.ok<IEvaluatedSchemaMetadata>({
      sourceEntityTypes: []
    });
  }
}
