import { FormControl, FormLabel } from "@chakra-ui/react";
import { GroupBase, Select } from "chakra-react-select";
import EnterspeedIdentifiableSelect from "../../../components/EnterspeedIdentifiableSelect";
import useReactEnterspeedIdentifiableSelectStyles from "../../../styles/react-enterspeed-identifiable-select-style";
import IEnterspeedIdentifiableSelectOption from "../../../types/identifiableSelectInput";
import { IEnvironmentResponse } from "../types";

const mapToSelectionOption = (environment: IEnvironmentResponse) =>
  ({
    label: environment?.name,
    value: environment?.id.idValue,
    id: environment?.id.idValue
  } as IEnterspeedIdentifiableSelectOption);

const EnvironmentsPicker = ({
  selected,
  environments,
  onChange
}: {
  environments?: IEnvironmentResponse[];
  onChange: (environment: IEnvironmentResponse | null) => void;
  selected: IEnvironmentResponse | null;
}) => {
  const reactEnterspeedIdentifiableSelectStyles =
    useReactEnterspeedIdentifiableSelectStyles();

  const getEnvironmentFromOption = (
    selectOption: IEnterspeedIdentifiableSelectOption
  ) =>
    environments?.find(
      (e) => e.id.idValue === (selectOption.value as string)
    ) ?? null;

  return (
    <FormControl>
      <FormLabel color="gray.500" fontSize="xs">
        Environment
      </FormLabel>
      <Select<
        IEnterspeedIdentifiableSelectOption,
        true,
        GroupBase<IEnterspeedIdentifiableSelectOption>
      >
        size="sm"
        useBasicStyles
        colorScheme="brand"
        chakraStyles={reactEnterspeedIdentifiableSelectStyles}
        placeholder="Select environment"
        onChange={(option) => {
          onChange(
            getEnvironmentFromOption(
              option as unknown as IEnterspeedIdentifiableSelectOption
            )
          );
        }}
        options={environments?.map(mapToSelectionOption) ?? []}
        value={selected ? mapToSelectionOption(selected) : undefined}
        components={new EnterspeedIdentifiableSelect()}
      ></Select>
    </FormControl>
  );
};

export default EnvironmentsPicker;
