import { Flex } from "@chakra-ui/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import ContentBox from "../components/ContentBox";
import LoadingAnimation from "../components/LoadingAnimation";
import { useEnvironments } from "../features/environments/api/getEnvironments";
import EnvironmentPicker from "../features/environments/components/EnvironmentPicker";
import { IEnvironmentResponse } from "../features/environments/types";
import useIndexes from "../features/indexes/api/useIndexes";
import IndexesTable from "../features/indexes/components/IndexesTable";
import { mapSchemasToIndexesTaleItems } from "../features/indexes/util/map";

const IndexesPage = () => {
  const flags = useFlags();
  const title = "Indexes";
  const environments = useEnvironments();
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<IEnvironmentResponse | null>(null);

  const indexes = useIndexes({
    environmentId: selectedEnvironment?.id.idValue
  });

  if (!flags.indexSchemas) {
    return <Navigate to="/" />;
  }

  if (environments.isLoading) {
    return <LoadingAnimation />;
  }

  return (
    <ContentBox title={title}>
      <Flex w={"30rem"}>
        <EnvironmentPicker
          selected={selectedEnvironment}
          environments={environments.data}
          onChange={setSelectedEnvironment}
        />
      </Flex>
      {selectedEnvironment && (
        <IndexesTable
          isLoading={indexes.isLoading}
          items={indexes.data?.map(mapSchemasToIndexesTaleItems) ?? []}
        ></IndexesTable>
      )}
    </ContentBox>
  );
};

export default IndexesPage;
