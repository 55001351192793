import { Flex, Icon, Text } from "@chakra-ui/react";
import { faRocketLaunch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndexActiveIcon = () => (
  <Flex gap={"2"} align={"center"}>
    <Text>Active</Text>
    <Icon as={FontAwesomeIcon} icon={faRocketLaunch} color={"green.600"}></Icon>
  </Flex>
);

export default IndexActiveIcon;
