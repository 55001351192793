import { ISchemaListResponse } from "../features/schemas/types";
import IEnterspeedIdentifiableSelectOption from "../types/identifiableSelectInput";
import { ValueType } from "./valueType";

export const getSchemaAliasOptions = (
  schemas: ISchemaListResponse[] | undefined,
  valueType?: ValueType | undefined | null,
  onlyDeployed?: boolean
): IEnterspeedIdentifiableSelectOption[] => {
  if (!schemas || schemas.length === 0) {
    return [];
  }

  return schemas
    .sort((a, b) => (a.viewHandle > b.viewHandle ? 1 : -1))
    .filter((schema) => !onlyDeployed || schema.deployments.length !== 0)
    .map((schema) => {
      return {
        id: schema.id.idValue,
        label: schema.viewHandle,
        value: valueType === "FULL" ? schema.id.idValue : schema.viewHandle,
        title: schema.name
      } as IEnterspeedIdentifiableSelectOption;
    });
};

export const getSchemaOptionByAlias = (
  schemas: ISchemaListResponse[] | undefined,
  alias: string,
  valueType?: ValueType | undefined | null
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!schemas || schemas.length === 0) {
    return undefined;
  }

  const schema = schemas.find((s) => s.viewHandle === alias);
  return schema
    ? {
        id: schema.id.idValue,
        label: schema.viewHandle,
        value: valueType === "FULL" ? schema.id.idValue : schema.viewHandle
      }
    : undefined;
};

export const getSchemaOptionById = (
  schemas: ISchemaListResponse[] | undefined,
  id: string,
  valueType?: ValueType | undefined | null
): IEnterspeedIdentifiableSelectOption | undefined => {
  if (!schemas || schemas.length === 0) {
    return undefined;
  }

  const schema = schemas.find((s) => s.id.idValue === id);
  return schema
    ? {
        id: schema.id.idValue,
        label: schema.viewHandle,
        value: valueType === "FULL" ? schema.id.idValue : schema.viewHandle
      }
    : undefined;
};
