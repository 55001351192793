import { ReactNode } from "react";
import { IEnvironmentResponse } from "../../environments/types";
import { ISchemaListResponse } from "./index";

export interface ISchemaListMenuProps {
  onDuplicateSchema(): void;
  onDeleteSchema(): void;
  onEditSchema(): void;
}

export interface ISchemaListItemProps {
  onDuplicateSchema(): void;
  onDeleteSchema(): void;
  onEditSchema(): void;

  schema: ISchemaListResponse;
  name: string;
  query: string;
  environments?: IEnvironmentResponse[];
}

export interface ISchemaListFolderProps {
  onCreateSchema(): void;
  onClick(): void;

  children: ReactNode;
  name: string;
  folderPath: string;
  isOpen: boolean;
}

export interface ISchemaListProps {
  schemas: ISchemaListResponse[];
  alwaysOpen: boolean;
  filterTerm: string;
  query: string;
  advancedSearch: boolean;
  isLoading: boolean;
  isRefetching: boolean;
  expandedPaths: string[];
  collapsedPaths: string[];

  filterButtons?: ReactNode;

  onDuplicateSchema(schemaGuid: string, schemaName: string): void;
  onDeleteSchema(schemaGuid: string, schemaName: string): void;
  onEditSchema(schemaGuid: string, schemaName: string): void;
  onCreateSchema(path: string | undefined): void;
}

export interface ISchemaListFilterProps {
  onTermChange(value: string): void;
  onAdvancedSearch(value: string): void;
  advancedSearch: boolean;
  term: string;
  query: string;
  children?: ReactNode;
}

export interface ITreeViewNodeProps {
  onDuplicateSchema(schemaGuid: string, schemaName: string): void;
  onDeleteSchema(schemaGuid: string, schemaName: string): void;
  onEditSchema(schemaGuid: string, schemaName: string): void;
  onCreateSchema(path: string | undefined): void;

  node: TreeNode;
  query: string;
  environments?: IEnvironmentResponse[];
}

export interface ITreeViewProps {
  onDuplicateSchema(schemaGuid: string, schemaName: string): void;
  onDeleteSchema(schemaGuid: string, schemaName: string): void;
  onEditSchema(schemaGuid: string, schemaName: string): void;
  onCreateSchema(path: string | undefined): void;

  data: TreeNode[];
  query: string;
  environments?: IEnvironmentResponse[];
}

export interface ITreeState {
  nodes: TreeNode[];
  alwaysOpen: boolean;
  advancedSearch: boolean;
  filterTerm: string;
  query: string;
  hasFolders: boolean;
  expandedPaths: Paths;
  collapsedPaths: Paths;
}

export interface TreeNode {
  key: string;
  name: string;
  hidden: boolean;
  type: "schema" | "folder";
}

export interface SchemaItemTreeNode extends TreeNode {
  data: ISchemaListResponse;
}

export interface VirtualFolderTreeNode extends TreeNode {
  children: TreeNode[];
  isExpanded: boolean;
  path: string;
}

export class Paths extends Set<string> {
  constructor(values?: string[] | undefined | null) {
    super(values);
  }

  addRange(paths: string[]): void {
    paths.forEach((path) => this.add(path));
  }

  deleteRange(paths: string[]): void {
    paths.forEach((path) => this.delete(path));
  }

  descendantsOrSelf(path: string): string[] {
    const matches = Array.from(this).filter(
      (f) => f.startsWith(`${path}/`) || path === f
    );
    if (!matches.includes(path)) {
      matches.push(path);
    }
    return matches;
  }
}

// Actions
export type InitializeAction = {
  type: "initialize";
  schemas: ISchemaListResponse[];
  alwaysOpen: boolean;
  advancedSearch: boolean;
  filterTerm: string;
  query: string;
  collapsedPaths: string[];
  expandedPaths: string[];
};

export type AdvancedSearchAction = {
  type: "advancedSearch";
  query: string;
};

export type SearchAction = {
  type: "search";
  term: string;
};

export type CollapseAllAction = {
  type: "collapse_all";
};

export type ExpandAllAction = {
  type: "expand_all";
};

export type EnableAdvancedSearchAction = {
  type: "advanced_search";
};

export type EnableSimpleSearchAction = {
  type: "simple_search";
};

export type CollapseAction = {
  type: "collapse";
  path: string;
};

export type ExpandAction = {
  type: "expand";
  path: string;
};

export type SchemasTreeAction =
  | InitializeAction
  | SearchAction
  | AdvancedSearchAction
  | EnableAdvancedSearchAction
  | EnableSimpleSearchAction
  | CollapseAllAction
  | ExpandAllAction
  | CollapseAction
  | ExpandAction;
