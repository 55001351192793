import { Flex, Icon, Text } from "@chakra-ui/react";
import { faBroomWide } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndexRemovingIcon = () => (
  <Flex className={"fa-fade"} gap={"2"} align={"center"}>
    <Text color={"gray.500"}>Removing</Text>
    <Icon
      className="fa-shake"
      as={FontAwesomeIcon}
      icon={faBroomWide}
      color={"gray.500"}
    ></Icon>
  </Flex>
);

export default IndexRemovingIcon;
